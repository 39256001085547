import sw from 'sweetalert2'
import { i18n } from '@core/utils/filter'

export const swal = sw.mixin({
  customClass: {
    confirmButton: 'btn btn-primary rounded-lg text-capitalize',
    denyButton: 'btn btn-danger rounded-lg ml-2 text-capitalize',
    cancelButton: 'btn btn-outline-primary rounded-lg ml-2 text-capitalize',
  },
  buttonsStyling: false,
  heightAuto: false
})

export const Toast = sw.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timerProgressBar: true,
  timer: 1500,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', swal.stopTimer)
    toast.addEventListener('mouseleave', swal.resumeTimer)
  },
  customClass:{
    popup: 'card mb-0',
    container: 'h-auto'
  }
})

export const Dialog = (param, confirmBtnColor) => sw.mixin({
  icon: param?'question':'error',
  title: param?i18n('form.confirm'):'',
  customClass: { 
    confirmButton: `btn btn-${confirmBtnColor||'primary'} rounded-lg shadow-sm text-capitalize`,
    denyButton: 'btn btn-danger rounded-lg ml-2 shadow-sm text-capitalize',
    cancelButton: 'btn btn-outline-danger rounded-lg shadow-sm ml-2 text-capitalize' 
  },
  showCancelButton:param,
  buttonsStyling: false,
  heightAuto: false
})

// 延迟
export const useDelay = ()=>{
  let awaitingStartTime = 0;
  const awaitAction = (func, duration=750) => {
    let selfStartTime = + new Date()
    awaitingStartTime = selfStartTime;
    setTimeout(()=>{
      if (awaitingStartTime === selfStartTime) {
        awaitingStartTime = 0
        func()
      }
    }, duration)
  }
  return { awaitingStartTime, awaitAction }
}