<template>
  <section class="sale-invoice-view">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="invoiceData === undefined" >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link class="alert-link" :to="{ name: 'sale-list'}">Invoice List</b-link>
        for other invoices.
      </div>
    </b-alert>

    <b-row v-if="invoiceData" class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <!-- 公司信息 -->
              <div v-if="companyData">
                <div class="logo-wrapper"><logo /><h3 class="text-primary invoice-logo">{{companyData.name}}</h3></div>
                <p class="card-text mb-25">{{companyData.address}}</p>
                <p class="card-text mb-25">{{companyData.unit}} {{companyData.country}} {{companyData.zip}}</p>
                <p class="card-text mb-0">{{companyData.contact}}</p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="text-right text-capitalize">{{(invoiceData.isTaxable?$t('supply-chain.invoice.tax_invoice'):$t('supply-chain.invoice._')).toUpperCase()}} <span class="invoice-number"># {{ invoiceData.saleSeq }}</span></h4>

                <div class="invoice-date-wrapper mt-3">
                  <p class="invoice-date-title">{{$t('supply-chain.invoice.issue_date')}}:</p>
                  <p class="invoice-date">{{ invoiceData.createdAt | dateString}}</p>
                </div>
                <div v-if="invoiceData.dueDate" class="invoice-date-wrapper">
                  <p class="invoice-date-title">{{$t('supply-chain.invoice.due_date')}}:</p>
                  <p class="invoice-date">{{ invoiceData.dueDate | dateString}}</p>
                </div>
                <div v-if="invoiceData.orderNo" class="invoice-date-wrapper">
                  <p class="invoice-date-title">{{$t('supply-chain.invoice.order')}}:</p>
                  <p class="invoice-date">{{ invoiceData.orderNo }}</p>
                </div>
                <div v-if="invoiceData.packing && invoiceData.packing.quantity" class="invoice-date-wrapper">
                  <p class="invoice-date-title text-capitalize">{{$t('form.quantity')}}:</p>
                  <p class="invoice-date">{{ invoiceData.packing.quantity }}</p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body class="invoice-padding pt-0">
            <div class="py-1 d-flex flex-row-reverse justify-content-between">
              <!-- 付款方式 -->
              <div class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end">
                <div>
                  <h6 class="mb-2">{{$t('supply-chain.invoice.payment_details')}}:</h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-2">Total Due:</td>
                        <td><span class="font-weight-bold">{{ paymentDetails.totalDue }}</span></td>
                      </tr>
                       <tr v-if="paymentDetails.bankName">
                        <td class="pr-2">{{$t('supply-chain.invoice.bank_name')}}:</td>
                        <td>{{ paymentDetails.bankName }}</td>
                      </tr>
                      <tr>
                        <td class="pr-2">{{$t('supply-chain.invoice.country')}}:</td>
                        <td>{{ paymentDetails.country }}</td>
                      </tr>
                      <tr v-if="paymentDetails.iban">
                        <td class="pr-2">{{$t('supply-chain.invoice.iban')}}:</td>
                        <td>{{ paymentDetails.iban }}</td>
                      </tr>
                      <tr v-if="paymentDetails.swift">
                        <td class="pr-2">{{$t('supply-chain.invoice.swift')}}:</td>
                        <td>{{ paymentDetails.swift }}</td>
                      </tr>
                       <tr v-if="paymentDetails.paynow">
                        <td class="pr-2">{{$t('supply-chain.invoice.paynow')}}:</td>
                        <td>{{ paymentDetails.paynow }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <!-- Invoice to -->
              <div v-if="invoiceData.billing" class="p-0">
                <h6 class="mb-2">{{$t('supply-chain.invoice.invoice_to')}}:</h6>
                <h6 class="mb-25">{{ invoiceData.billing.name }}</h6>
                <p class="card-text mb-25">{{ invoiceData.billing.company }}</p>
                <p class="card-text mb-25">{{ invoiceData.billing.address }}</p>
                <p class="card-text mb-25">{{ invoiceData.billing.contact }}</p>
                <p class="card-text mb-0">{{ invoiceData.billing.email }}</p>
              </div>
            </div>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <BTableLite responsive class="variants text-center border-bottom overflow-hidden" :items="invoiceData.variants" striped :fields="[
            { key:'description', label:$t('supply-chain.invoice.description'), thClass:'text-left'},
            { key:'price', label:$t('supply-chain.invoice.price')}, 
            { key:'quantity', label:$t('supply-chain.invoice.quantity')},
            { key:'amount', label:$t('supply-chain.invoice.total'), thClass:'text-right', tdClass:'text-right'}]">
            <template #cell(description)="{item}">
              <b-card-text class="font-weight-bold mb-25 text-left">
                {{ item.productNames.find(n=>n.locale===$i18n.locale).name }} - 
                {{ item.names.find(n=>n.locale===$i18n.locale).name }}
              </b-card-text>
              <b-card-text v-if="item.remarks" class="text-nowrap text-left">{{ item.remarks }}</b-card-text>
            </template>
            <template #cell(price)="{value, item}">{{value | price($t(`currency.${item.currency}.sign`))}}</template>
            <template #cell(amount)="{value, item}">{{value | price($t(`currency.${item.currency}.sign`))}}</template>
          </BTableLite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col cols="12" md="6" class="mt-md-0 mt-3" order="2" order-md="1">
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">Salesperson:</span>
                  <span class="ml-75">Alfie Solomons</span>
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2" >
                <div class="invoice-total-wrapper">
                  
                  <!-- coupon -->
                  <template v-if="invoiceData.couponValue">
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">{{$t('supply-chain.invoice.order_total')}}</p>
                      <p class="invoice-total-amount">{{invoiceData.variants.reduce((a,v)=>a+v.amount,0) | price($t(`currency.${invoiceData.currency}.sign`))}}</p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">{{$t('supply-chain.invoice.discount')}}:</p>
                      <p class="invoice-total-amount font-weight-normal">- {{ invoiceData.couponValue | price($t(`currency.${invoiceData.currency}.sign`)) }}</p>
                    </div>
                  </template>
                  <!-- Tax -->
                  <template v-if="invoiceData.isTaxable">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">{{$t('supply-chain.invoice.subtotal')}}</p>
                      <p class="invoice-total-amount">{{ invoiceData.subtotal | price($t(`currency.${invoiceData.currency}.sign`)) }}</p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">{{invoiceData.taxRate}}% {{$t('supply-chain.invoice.tax')}}:</p>
                      <p class="invoice-total-amount font-weight-normal">{{ invoiceData.taxAmount | price($t(`currency.${invoiceData.currency}.sign`)) }}</p>
                    </div>
                    <hr class="my-50">
                  </template>
                  
                  <!-- total -->
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">{{$t('supply-chain.invoice.total')}}:</p>
                    <p class="invoice-total-amount">{{ invoiceData.total | price($t(`currency.${invoiceData.currency}.sign`)) }}</p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0 foot">
            <hr class="invoice-spacing mt-5">
            <span class="font-weight-bold">Note: </span>
            <span>It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance
              projects. Thank You!</span>
            <div>{{$t('supply-chain.invoice.footer')}}</div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="$router.go(-1)" class="mb-75 text-capitalize" block>
            <fa-icon icon="undo" class="mr-75" />{{$t('form.back')}}
          </b-button>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-secondary" v-b-toggle.sidebar-send-invoice class="mb-75 text-capitalize" block>
            <fa-icon icon="envelope" class="mr-75" />{{$t('supply-chain.invoice.send')}}
          </b-button>

          <!-- Button: Print -->
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" class="mb-75 text-capitalize" block @click="printInvoice">
            <fa-icon icon="print" class="mr-75" />{{$t('action.print')}}
          </b-button>

          <!-- Button: Add Payment -->
          <b-button v-b-toggle.sidebar-invoice-add-payment v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mb-75" block >
            Add Payment
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <InvoiceSidebarSendInvoice />
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import axios from '@axios'
import { Toast } from '@core/utils/other'
import { useRouter } from '@core/utils/utils'
import { dateString, price } from '@core/utils/filter'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BTr, BTd
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import useSale from './useSale'
import InvoiceSidebarSendInvoice from './InvoiceSidebarSendInvoice'

export default {
  name: 'sale-invoice-view',
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  filters: {
    dateString, price
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BTr, BTd,

    Logo,
    InvoiceSidebarSendInvoice
  },
  setup() {
    const { route } = useRouter()
    const invoiceData = ref(null)
    const paymentDetails = ref({})
    const companyData = ref({})
    const { fetchSale } = useSale()

    const invoiceDescription = [
      {
        taskTitle: 'Native App Development',
        taskDescription: 'Developed a full stack native app using React Native, Bootstrap & Python',
        rate: '$60.00',
        hours: '30',
        total: '$1,800.00',
      },
      {
        taskTitle: 'UI Kit Design',
        taskDescription: 'Designed a UI kit for native app using Sketch, Figma & Adobe XD',
        rate: '$60.00',
        hours: '20',
        total: '$1200.00',
      }
    ]

    fetchSale(route.value.params.id, sale=>{
      invoiceData.value = sale
    })

    const printInvoice = () => {
      window.print()
    }

    axios.get(`public/company`)
      .then(response => companyData.value = response.data.company)
      .catch(error => Toast.fire({icon:'error', title: error.message}))

    axios.get(`public/payment-details`)
      .then(response => paymentDetails.value = response.data.paymentDetails)
      .catch(error => Toast.fire({icon:'error', title: error.message}))

    return {
      companyData,
      invoiceData,
      paymentDetails,
      invoiceDescription,
      printInvoice,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .sale-invoice-view {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }

    .variants table td:first-child, table th:first-child {
      padding-left: 2.5rem;
    }
    .variants table td:last-child, table th:last-child {
      padding-right: 2.5rem;
    }

    .foot {
      position: fixed;
      bottom: 0;
    }
  }
}
.sale-invoice-view  {
  .variants table td:first-child, table th:first-child {
    padding-left: 2.5rem;
  }
  .variants table td:last-child, table th:last-child {
    padding-right: 2.5rem;
  }
}
</style>
